/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Helmet from 'react-helmet';

import Header from "./Header/Header"
// import Footer from "./footer/footer"
import Footer from './Footer/Footer';
// import InstagramMedia from './InstagramMedia/InstagramMedia.js';
import './layout.css';

const Layout = ({ children, siteAlert }) => {
  const [ emailValue, setEmailValue ] = useState('');
  const [ nameValue, setNameValue ] = useState('');

  useEffect(() => {
    // Google Tag Manager
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-TBP2KWJ');
  })
  return (
    <>
      {/* Google Tag Manager (noscript) */}
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TBP2KWJ" height="0" width="0" style={{
        display:"none", visibility:"hidden"
      }}></iframe></noscript>
      {/* End Google Tag Manager (noscript) */}
      <Helmet>
        <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css" />
        <script type='text/javascript' dangerouslySetInnerHTML={{html: `(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';}(jQuery));var $mcj = jQuery.noConflict(true);`}}></script>
      </Helmet>
      <Header siteAlert={siteAlert} />
      <main>{children}</main>
      {/* <InstagramMedia account="hockeyns" numberOfMediaElements={4} /> */}
      <div className="mailhchimpnewsletter">
          <div id="mc_embed_signup">
            <form action="https://hockeynovascotia.us11.list-manage.com/subscribe/post?u=2de35ec5cffa84eed5d06cf07&amp;id=5f9aa3df84" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
              <div id="mc_embed_signup_scroll">
                <h2 className="signup-title">Newsletter Sign-up</h2>
                {/* <div className="indicates-required"><span className="asterisk">*</span> indicates required</div> */}
                <div className="mc-field-group">
                    <input 
                        type="email" 
                        name="EMAIL" 
                        className="email" 
                        id="mce-EMAIL" 
                        placeholder="Email" 
                        value={emailValue} 
                        onChange={ (e) => setEmailValue(e.target.value) } 
                    />
                    <label htmlFor="mce-EMAIL">Email Address </label>
                </div>
                <div className="mc-field-group">
                    <input 
                        type="text" 
                        name="FNAME" 
                        className="" 
                        id="mce-FNAME" 
                        placeholder="Full Name"
                        value={nameValue} 
                        onChange={ (e) => setNameValue(e.target.value) }
                    />
                    <label htmlFor="mce-FNAME">Name </label>
                </div>
                {/* <div id="mce-responses" className="clear">
                  <div className="response" id="mce-error-response" style={{display:"none"}}></div>
                  <div className="response" id="mce-success-response" style={{display:"none"}}></div>
                </div> */}
                  <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_2de35ec5cffa84eed5d06cf07_5f9aa3df84" tabIndex="-1" value=""/></div>
                  <div className="clear"><button type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button submit-btn">submit</button></div>
                </div>
                <div className="error-msg">
                    <div id="mce-responses" className="clear">
                    <div className="response" id="mce-error-response" style={{display:"none"}}></div>
                    <div className="response" id="mce-success-response" style={{display:"none"}}></div>
                    </div>
                </div>
            </form>
            </div>
          </div>
          <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
        <Footer />
        {/* <Footer/> */}
    </>
  )
}

Layout.propTypes = {
        children: PropTypes.node.isRequired,
}

export default Layout
