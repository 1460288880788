import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import FormatDate from './../../helpers/formatDate';

const NewsCallout = ({title, cTALabel}) => {
    const [newsItems, setNewsItems] = useState(false);
    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    infinite: false,
                    centerMode: false,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    infinite: true,
                    centerMode: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    }
    useEffect(() => {
        async function getNewsItems() {
            if (window.__agilityApi)   {
                let items = await window.__agilityApi.getContentList("newsandevents");
                setNewsItems(items.items);
            }
        }
        getNewsItems()
    }, [])
    return<div className='news-callout'>
        <div className="row">
            {!!title && <h2 className="title">{title}</h2>}
            {!!cTALabel && <div className="cta cta-desktop"><button className="button-bare">{cTALabel}</button></div>}
        </div>
        <Slider {...sliderSettings}>
            {!!newsItems && newsItems.map((item, i) => {
                return <article key={i}>
                    <div className="background-container">
                        <div style={{background: !!item.fields.thumbnail ? 'linear-gradient(to top, rgba(0,0,0,0.6), rgba(0,0,0,0)), url(' + item.fields.thumbnail.url + ')' : ''}} className={`bckg`}></div>
                    </div>
                    {!!item.fields.date && <small className="date">{FormatDate(item.fields.date)}</small>}
                    {!!item.fields.title && <small className="title">{item.fields.title}</small>}
                </article>
            })}
        </Slider>
        <div className="row">
            {!!cTALabel && <div className="cta cta-mobile"><button className="button-bare">{cTALabel}</button></div>}
        </div>
    </div>
}

export default NewsCallout;