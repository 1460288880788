import HighlightedPages from './HighlightedPages';
import Hero from './Hero/Hero';
import Banner from './Banner/Banner';
import BoardOfDirectors from './BoardOfDirectors/BoardOfDirectors';
import Slideshow from './Slideshow/Slideshow';
import SiteWideAlert from './SiteWideAlert/SiteWideAlert';
import NewsCallout from './NewsCallout/NewsCallout';
import ResourceList from './ResourceList/ResourceList'
import RichTextArea from './RichText/RichText';
import SearchResults from './SearchResults/SearchResults';
import CardSliderCallout from './CardSliderCallout/CardSliderCallout';
import PageTitle from './PageTitle/PageTitle';
import Heading from './Heading/Heading';
import MediaAndText from './MediaAndText/MediaAndText';
import TwoColumnContent from './2ColumnContent/2ColumnContent.js';
import ThreeColumnContent from './ThreeColumnContent/ThreeColumnContent';
import NewsandEventsListing from './NewsandEventsListing/NewsandEventsListing'
import NewsandEventsDetail from './NewsandEventsDetail/NewsandEventsDetail';
import AgilityFormBuilder from './AgilityFormBuilder/AgilityFormBuilder'

const AgilityModules = {
    HighlightedPages,
    Heading,
    Hero,
    Banner,
    BoardOfDirectors,
    Slideshow,
    SiteWideAlert,
    ResourceList,
    NewsCallout,
    RichTextArea,
    SearchResults,
    CardSliderCallout,
    PageTitle,
    MediaAndText,
    NewsandEventsListing,
    NewsandEventsDetail,
    ThreeColumnContent,
    AgilityFormBuilder,
    '2ColumnContent': TwoColumnContent
}

export default AgilityModules;
