import React, {useState, useEffect} from 'react';

const HighlightedPages = (props) => {
    const [menuItems, setMenuItems] = useState([])
    useEffect(() => {
        const normalizeMenuChildren = (children) => {
            let menu = [];
            !!children.length && children.map(c => {
                menu.push({
                    title: c.customFields.title,
                    url: c.customFields.url.href,
                })
            })
            return menu;
        }
        const children1 = !!props.pageLinks1 ? normalizeMenuChildren(props.pageLinks1) : []
        const children2 = !!props.pageLinks2 ? normalizeMenuChildren(props.pageLinks2) : []
        const children3 = !!props.pageLinks3 ? normalizeMenuChildren(props.pageLinks3) : []
        const children4 = !!props.pageLinks4 ? normalizeMenuChildren(props.pageLinks4) : []
        const children5 = !!props.pageLinks5 ? normalizeMenuChildren(props.pageLinks5) : []
        const tmpMenuItems = [
            {
                title: props.pageLabel1,
                url: '#',
                image: props.image1.url,
                children: children1
            }, {
                title: props.pageLabel2,
                url: '#',
                image: props.image2.url,
                children: children2
            }, {
                title: props.pageLabel3,
                url: '#',
                image: props.image3.url,
                children: children3
            }, {
                title: props.pageLabel4,
                url: '#',
                image: props.image4.url,
                children: children4
            }, {
                title: props.pageLabel5,
                url: '#',
                image: props.image5.url,
                children: children5
            },
        ];
        setMenuItems(tmpMenuItems);
    }, [])

    return (<nav className="highlighted_pages_nav">
        <ul className="highlighted_pages_list">
            {menuItems.map( ({title, url, image, children=[]}, idx) => (<li key={idx} className="highlighted_page">
                <article className="highlighted_page_item">
                <div className={`background-container bckg bg-g-primary`} style={{backgroundImage: !!image ? 'url(' + image + ')' : ''}}></div>
                    <figure className="highlighted_page_image">
                        <img src={`${image}?w=1200`} alt={title} />
                    </figure>
                    <section className="hightlighted_page_content">
                        <h2 className="highlighted_page_title">{title}</h2>
                        {children.length > 0 && <ul className="highlighted_page_children">
                            {children.map( ({title, url}, i) => (<li key={i}><a href={url}>{title}</a></li>) )}
                        </ul>}
                    </section>
                </article>
            </li>) )}
        </ul>
    </nav>)
}

export default HighlightedPages;