import React, {useState, useEffect} from 'react';
import { window } from 'browser-monads';
import Layout from './components/layout';
import { graphql } from "gatsby"
import SEO from './components/seo';
import agilityUtils from './helpers/utils'
import ZoneRender from './components/ZoneRender';
import './styles/styles.scss';
import deepMapKeys from 'deep-map-keys';


// Our query to get the our page data and check for a dynamic page item (agilityItem)
export const query = graphql`
  query($pageID: Int!, $contentID: Int!, $languageCode: String!) {

    agilitypage(languageCode: { eq: $languageCode }, itemID: { eq: $pageID }) {
        pageJson
	}
    agilityitem(languageCode: {eq: $languageCode}, itemID: {eq: $contentID}) {
		itemJson
    }
}`

const AgilityPage = ({ pageContext, data }) => {
    const viewModel = agilityUtils.buildPageViewModel({ pageContext, data });
    const [vm, setVm] = useState(viewModel);
    const [previewPages, setpreviewPages] = useState(!!window && window.location.href.indexOf('agilitypreviewkey') > -1);
    let isDynamicPage = false;
    useEffect(() =>{
        async function getpagedata(id) {
            let p = await  window.__agilityPreviewApi.getPage(id);
            let _p = deepMapKeys(p, key => key === 'fields' ? 'customFields' : key);
            console.log(_p);
            setpreviewPages({page: _p})
        }
        if (window.location.href.indexOf('agilitypreviewkey') > -1) {
            getpagedata(vm.page.pageID);
        }
    }, []);
    if (!!window && window.location.href.indexOf('agilitypreviewkey') > -1) {
        console.log("Preview mode");
    }
    isDynamicPage = viewModel?.dynamicPageItem;
    const sitealert = [];
    !!viewModel && Object.keys(viewModel.page.zones).map(zoneName => {
        !!viewModel.page.zones[zoneName] && viewModel.page.zones[zoneName].map(zone => {
            if (zone.module === 'SiteWideAlert') {
                sitealert.push(zone.item.customFields)
            }
        })
    })   

    return (
        <Layout siteAlert={sitealert}>
            <SEO title={viewModel.page.menuText} description={viewModel.page.seo ? viewModel.page.seo.metaDescription: ''} />
            {!previewPages && !!viewModel && Object.keys(viewModel.page.zones).map(zoneName => <ZoneRender zoneName={zoneName} zone={viewModel.page.zones[zoneName]} key={zoneName} isDynamicPage={isDynamicPage}/>)}
            {previewPages === true && 'loading preview..'}
            {typeof(previewPages) === 'object' && Object.keys(previewPages.page.zones).map(zoneName => <ZoneRender zoneName={zoneName} zone={previewPages.page.zones[zoneName]} key={zoneName} />)}
        </Layout>
    )
}

export default AgilityPage;
