import React, {useState} from 'react'

const Control = (f) => {
    const [value, setValue] = useState(null);
    const dataType = [
        'text', 
        'textarea',
        'number',
        'number',
        'datetime-local',
        'file',
        'text',
        'text',
        'text',
        'checkbox',
        'select', 
        'textarea', // HTML
        'email'
    ]
    
    const type = dataType[f.DataType];
    const name = !!f.Name ? f.Name.toLowerCase() : '';

    const onFormChange = (name, e) => {
        f.onChange(name, e)
        setValue(e.target.value)
    }

    if(type === 'textarea') {
        return <>
            <textarea name={name} className="form-control" onChange={(e) => onFormChange(name, e)}></textarea>
            <label htmlFor={name} className={`${!!value && "label-active"}`}>{f.Name}</label>
        </>
    } else if(type === 'checkbox') {
        return <label>
            <input type={type} value={f.Name} className="form-control" onChange={(e) => onFormChange(name, e)}/> {f.Name}
        </label>
    } else if(type  === 'select'){
        return <select name={name} className="form-control" onChange={(e) => onFormChange(name, e)}></select>
    }
    return <>
        <input type={dataType[f.DataType]} className="form-control" onChange={(e) => onFormChange(name, e)}/>
        <label htmlFor={name} className={`${!!value && "label-active"}`}>{f.Name}</label>
    </>
}

const AgilityFormBuilder = (props) => {
    const [data, setData] = useState({});
    const form = JSON.parse(props.additionalJSON);
    console.log({form})
    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const handleFormChange = (name, e) => {
        let tmp = data;
        tmp[name] = e.target.value;
        setData(tmp);
        console.log({tmp})
    }

    return <section className="agility-form">
        <div className="container">
            <form className="agility-form-form" onSubmit={handleSubmit}>
                {!!form && form.map((f, i) => <div className="form-group" key={i}>
                    <Control {...f} onChange={handleFormChange}/>
                </div>)}
                <button className="agility-form-submit">{props.submitButtonLabel}</button>
            </form>
        </div>
    </section>
}

export default AgilityFormBuilder;

