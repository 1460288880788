import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

function useWindowSize() {
    const isClient = typeof window === 'object';

    function getSize() {
        return {
            width: isClient ? window.innerWidth : 0,
            height: isClient ? window.innerHeight : 0
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}

const CardSliderCallout = ({ items }) => {
    const [flippedCard, setFlippedCard] = useState(false);
    const size = useWindowSize();
    const flipCard = (e, contentID) => {
        if (flippedCard === contentID) {
            setFlippedCard(false);
        } else {
            setFlippedCard(contentID);
        }
    }
    const sliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1292,
                settings: {
                    infinite: true,
                    centerMode: false,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    infinite: true,
                    centerMode: false,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    infinite: true,
                    centerMode: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 567,
                settings: {
                    infinite: true,
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    }
    const isMobile = size.width <= 768;
    return (<div className='card-slider-callout'>
        <Slider {...sliderSettings}>
            {!!items && items.map((item) => {
                return <article onMouseLeave={(e) => flipCard(e, item.contentID)} onMouseEnter={(e) => flipCard(e, item.contentID)} key={item.contentID} className={`flipped-${!!flippedCard && flippedCard === item.contentID ? 'true' : 'false'}`}>
                    <div className={`cardslidercard mobile-${isMobile}`}>
                        <figure className="cardeffect">
                            {!!item.customFields.thumbnail && <img src={item.customFields.thumbnail.url} alt="" />}
                        </figure>
                        <div className="cardcontent">
                            <h5 className="c-background">{item.customFields.title}</h5>
                            <div className="carditemcontent" dangerouslySetInnerHTML={{ __html: item.customFields.content }} />
                        </div>
                    </div>
                </article>
            })}
        </Slider>
    </div>)
}

export default CardSliderCallout;
