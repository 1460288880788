import React from 'react';
import RichText from './../RichText/RichText';
import Button from './../Button/Button';

const Banner = ({backgroundImage, backgroundOverlay, cTA, content}) => {
    return<div className={`banner bckg with-bg-g-${backgroundOverlay} bg-g-${backgroundOverlay}`} style={{backgroundImage: !!backgroundImage ? 'url(' + backgroundImage.url + ')' : ''}}>
        <div className="row">
            <div className="content-container">
                {!!content && <RichText content={content}/>}
                {!!cTA && <Button style={backgroundOverlay === 'primary' ? 'secondary' : false} href={cTA.href} target={cTA.target} text={cTA.text}/>}
            </div>
        </div>
    </div>
}

export default Banner;
