import React from 'react';
import { Helmet } from 'react-helmet'
import FormatDate from './../../helpers/formatDate';

const NewsandEventsDetail = (props) => {
    const { content, date, thumbnail, title } = props.isDynamicPage.customFields;
    return(
        <section className="news-and-events-details row">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{title.toUpperCase()}</title>
          </Helmet>
        <div className="content-container">
            {!!date && <small className="date">{FormatDate(date)}</small>}
            <h2 className="news-and-events-details-title">{title}</h2>
            {!!thumbnail && <figure>
                <img src={thumbnail.url} alt={thumbnail.label} />
            </figure>}
            <div className="news-and-events-details-content" dangerouslySetInnerHTML={{__html: content}} />
        </div>
        </section>
    );
}

export default NewsandEventsDetail;
