const primaryMenu = [{
    title: "About Us", url: "/",
    children: [
        { title: "History and values", url: "/" },
        { title: "Awards", url: "/" },
        { title: "Social", url: "/" },
        { title: "Innovation Incubator", url: "/" },
        { title: "News and Events", url: "/" },
        { title: "Member Associations", url: "/" },
        { title: "Funding Partners", url: "/" },
        { title: "Donations ", url: "/" },
        { title: "Alumni (engagement)", url: "/" },
        { title: "Contact us", url: "/" },
        { title: "Shop", url: "/" }]
}, {
    title: 'Players',
    url: '/',
    description: `Silky mittens High hockey I.Q. Wheel. Snipe. Celly. We wanna move the puck north south not east west. Gap up. C's gotta swing down low on the breakout. Open up a lane. Go fer a skate, bud. "Direct kicking motion." This beauty right here.`,
    children: [
        {
            title: `New Player`,
            url: `/`,
            children: [
                { title: `What to expect`, url: '/', description: `Winning puck battles. Put the puck on net and good things happen! Drop the gloves.` },
                { title: `Resources`, url: '/', },
                { title: `Support`, url: '/', },
                { title: `Test URL`, url: '/', },
            ]
        },
        {
            title: `Minor `, url: `/`,
            children: [
                { title: `What to expect`, url: `/` },
                { title: `Resources`, url: `/` },
                { title: `Support`, url: `/` }
            ]
        },
        {
            title: `Female`, url: `/`,
            children: [
                { title: `What to expect`, url: `/` },
                { title: `Resources`, url: `/` },
                { title: `Support`, url: `/` }
            ]
        },
        {
            title: `Junior`, url: `/`,
            children: [
                { title: `What to expect`, url: `/` },
                { title: `Resources`, url: `/` },
                { title: `Support`, url: `/` }
            ]
        },
        {
            title: `Para Hockey `, url: `/`,
            children: [
                { title: `What to expect`, url: `/` },
                { title: `Resources`, url: `/` },
                { title: `Support`, url: `/` }
            ]
        },
        {
            title: `High Performance`, url: `/`,
            children: [
                { title: `What to expect`, url: `/` },
                { title: `Resources`, url: `/` },
                { title: `Support`, url: `/` }
            ]
        },
        {
            title: `Adult Rec`, url: `/`,
            children: [
                { title: `What to expect`, url: `/` },
                { title: `Resources`, url: `/` },
                { title: `Support`, url: `/` }
            ]
        }
    ]
},
{
    title: 'Parent',
    url: '/',
    description: `Obviously uhh Make 'em pay for it. We're gonna have to go upstairs for this one. Rifled it glove side. Ooohhh baby! Get to the front and get a stick on it. Trust the process. "Direct kicking motion." Goalie's buyin' dinner tonight! Y'know`,
    children: [
        {
            title: `Current Hockey Parent`, url: `/`,
            children: [
                { title: `What to expect`, url: `/` },
                { title: `Resources`, url: `/` },
                { title: `Support`, url: `/` }
            ]
        },
        {
            title: `New Hockey Parents`, url: `/`,
            children: [
                { title: `What to expect`, url: `/` },
                { title: `Resources`, url: `/` },
                { title: `Support`, url: `/` }
            ]
        }
    ]
},
{
    title: 'Coach',
    url: '/',
    description: `Silky mittens High hockey I.Q. Wheel. Snipe. Celly. We wanna move the puck north south not east west. Gap up. C's gotta swing down low on the breakout. Open up a lane. Go fer a skate, bud. "Direct kicking motion." This beauty right here.`,
    children: [
        { title: `What to expect`, url: `/` },
        { title: `Resources`, url: `/` },
        { title: `Support`, url: `/` }
    ]
},
{
    title: 'Official',
    url: '/',
    description: `Silky mittens High hockey I.Q. Wheel. Snipe. Celly. We wanna move the puck north south not east west. Gap up. C's gotta swing down low on the breakout. Open up a lane. Go fer a skate, bud. "Direct kicking motion." This beauty right here.`,
    children: [
        { title: `What to expect`, url: `/` },
        { title: `Resources`, url: `/` },
        { title: `Support`, url: `/` }
    ]
},
{
    title: 'Member 	',
    url: '/',
    description: `Snipe. Celly. We wanna move the puck north south not east west. Gap up. C's gotta swing down low on the breakout. Open up a lane. Go fer a skate, bud. "Direct kicking motion." This beauty right here. We're gonna have to go upstairs for this one.`
}];

const quickLinks = [
    {
        title: `Club Finder`, url: `/`,
        chiildren: [
            {title: `Club near me`, url: `/`},
            {title: `Support`, url: `/`}
        ]
    },
    {
        title: `Tournaments`, url: `/`,
        chiildren: [
            {title: `List view of Tournaments`, url: `/`},
            {title: `Register`, url: `/`}
        ]
    },
    {
        title: `Programs`, url: `/`,
        chiildren: [
            {title: `> List view of programs`, url: `/`},
            {title: `Junior Coach Leadership Program`, url: `/`},
            {title: `Black Youth Ice Hockey`, url: `/`},
            {title: `New Canadians Program`, url: `/`},
            {title: `Blind Hockey`, url: `/`},
            {title: `Special Needs Hockey`, url: `/`},
            {title: `Register`, url: `/`}
        ]
    },
    {
        title: `Leagues`, url: `/`,
        chiildren: [
            {title: `List view of Leagues`, url: `/`},
            {title: `Register`, url: `/`}
        ]
    },
    {
        title: `Safety`, url: `/`,
        chiildren: [
            {title: `Helpful Resources`,url: `/`},
            {title: `Policies`,url: `/`},
            {title: `Complaint Form`,url: `/`},
        ]
    },
    {
        title: `Need Help	`, url: `/`,
        chiildren: [{
            title: `Contact details`,
            url: `/`
        }]
    }
]

const menuProps = {
    primaryMenu,
    quickLinks
}

export default menuProps;