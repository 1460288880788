import React, { useEffect } from 'react';

const Heading = ({level, content, jumplink, display}) => {
    useEffect(() => {
        // if (!!document.getElementById('jumplinks-container') && !!jumplink) {
        //     let jumplinkList = document.querySelector('#jumplinks-container div ul');
        //     let listItem = document.createElement("li");
        //     listItem.innerHTML = `<a href="#${kabobed()}">${content}</a>`
        //     jumplinkList.appendChild(listItem);
        // }
    }, []);

    let Tag = `${level}`
    
    const kabobed = () => {
        let kabobedText = content.toLowerCase();
        let specialChars = "._~:/?#[]@!$&'()*+,;=..".split('');
        let match = specialChars.filter((char) => kabobedText.indexOf(char) > -1);
        match.forEach(function(char) {
            kabobedText = kabobedText.replace(char, '');
        });
        kabobedText = kabobedText.split(' ').join('-');
        return kabobedText;
    }
    return <div className="heading row"><Tag id={!!jumplink ? kabobed() : null}>{display == 'true' && content}</Tag></div>;
}

export default Heading;
