import React, {useState, useEffect} from 'react';

const ResourceList = ({resourceIds, resources}) => {
    return (
        <section className="resource-listing container">
            {!!resources && resources.map((resource, i) => (
                <a href={resource.customFields.link.href} target={resource.customFields.link.target} className="resource" style={{backgroundImage: `url(${resource.customFields.image.url})`}} key={i}>
                    <div className="resource-background"></div>
                    <div className="resource-description">
                        <h3 className="resource-title">{resource.customFields.title}</h3>
                        <p className="resource-excerpt">{resource.customFields.description}</p>
                    </div>
                </a>
            ))}
        </section>
    )
}

export default ResourceList;