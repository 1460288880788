import React, {useEffect, useState} from 'react'
import axios from 'axios'
import loading from './loading.svg';

const SearchResults = () => {
    const [results, setResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState('')
    useEffect((e) => {
        async function initial () {
            const setting = await window.__agilityApi.getContentItem(847);
            if(!!setting) {
                const search = new URL(window.location.href).searchParams.get('s');
                setSearchTerm(search);
                axios.get(`https://www.googleapis.com/customsearch/v1?key=${setting.fields.apiKey}&cx=${setting.fields.searchengineID}&q=${search}`)
                .then(res => {
                    let r = [];
                    console.log({res})
                    !!res.data.items && res.data.items.map(item => {
                        r.push({
                            title: item.title,
                            url: item.link,
                            content: item.snippet
                        })
                    })
                    setResults(r)
                })
                .catch(error => {
                    console.log({error})
                    setResults([])
                })
            }
        }
        initial()
    }, [])

    return <section className="search-results row">
        {!!results.length && <div className="results">
            <h1 className="search-title">{results.length} results for "{searchTerm}"</h1>
            <ul className="search-list">
                {results.map((r, i) => <li key={i}>
                    <a href={r.url}>{r.title}</a>
                    <p>{r.content}</p>
                </li>)}
            </ul>
        </div>}
        {!!!results.length && <img src={loading} alt="loading..." className="search-loading"/>}
    </section>
}

export default SearchResults;