import React from 'react';

const PageTitle = (props) => {
    const {title, backgroundImage, jumplinks, desktop, mobile} = props;
    
    const handleScrolling = (e, href) => {
        e.preventDefault();
        const $selector = document.querySelector(`#${href}`);
        if(!!$selector) {
            const top = $selector.offsetTop
            window.scrollTo(0, (top - 200)); 
        }
    }
    
    const kabobed = (content) => {
        let kabobedText = content.toLowerCase();
        let specialChars = "._~:/?#[]@!$&'()*+,;=..".split('');
        let match = specialChars.filter((char) => kabobedText.indexOf(char) > -1);
        match.forEach(function(char) {
            kabobedText = kabobedText.replace(char, '');
        });
        kabobedText = kabobedText.split(' ').join('-');
        return kabobedText;
    }

    return <div className={`page-title sp-m-md mobile-${mobile} desktop-${desktop}`}>
        <div className={`bckg bg-g-tertiary`} style={{backgroundImage: !!backgroundImage ? 'url(' + backgroundImage.url.replace(/\s/g, '%20') + ')' : ''}}></div>
        <div className="row">
            <h1 className="title">{title}</h1>
        </div>
        {!!jumplinks && <div id="jumplinks-container">
            <div className="wrapper">
                <ul>{jumplinks.map((item, i) =>  {
                    let href = kabobed(item.content)
                    return <li key={i}>
                    <a href={`#${href}`} onClick={(e) => handleScrolling(e, href)}>{item.content}</a>
                </li>})}</ul>
            </div>
        </div>}
    </div>;
}

export default PageTitle;
