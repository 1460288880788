import React from 'react';
import AgilityModules from './AgilityModules';
import _ from 'lodash'

const Raw = (props) => {
    console.log(props);
    return false;
};

const ZoneRender = (props) => {
    const {zone, zoneName, isDynamicPage} = props;

    let jumplinks = []
    let gIndex = 0;
    !!zone && zone.map(m => {
        if(m.module === 'Heading') {
            jumplinks.push({...m.item.customFields});
        }

        if(m.module ===  "StartNewsletterWrapUp" || m.module === "StopNewsletterWrapUp") {
            gIndex++;
        }
        m.group = gIndex;
    })

    let pageTitleIndex = zone.findIndex(z => z.module === "PageTitle")
    if(pageTitleIndex > -1) {
        zone[pageTitleIndex].item.customFields['jumplinks'] = jumplinks;
    }

    let groupedZones = _.groupBy(zone, module => module.group);
    let groupedArray = [];
    for (const property in groupedZones) {
        groupedArray.push(groupedZones[property]);
    }
    
    const RenderComponent = ({group}) => {
        return !!group && group.map((m, i) => {
            let CurrentComponent = Raw;
            if (AgilityModules.hasOwnProperty(m.module)) {
                CurrentComponent = AgilityModules[m.module];
            }
            
            if (!!m.item && m.module !==  "StartNewsletterWrapUp" && m.module !== "StopNewsletterWrapUp" && m.module !== "SiteWideAlert") {
                return <CurrentComponent className={!!m.noContainer ? '' : 'container'} key={i} componentName={m.module} {...m.item.customFields} {...m.item.properties} isDynamicPage={isDynamicPage}/>
            }
            return null;
        })
    }

    const WrapUpComponent = ({group}) => {
        const newsletter = !!group.length && group[0].item.customFields;
        return <div className={`wrap-up-newsletter ${!!newsletter.turnOnBackgroundOverlay && newsletter.turnOnBackgroundOverlay == 'true' && 'bg-overlay'}`} style={!!newsletter && !!newsletter.backgroundImage ? {backgroundImage: `url(${newsletter.backgroundImage.url})`} : {}}>
                    <div className="container">
                        {!!newsletter && <h2 className="newsletter-title">{newsletter.newsletterTitle}</h2>}
                        {group.map((g, i) => <RenderComponent group={[g]} key={i}/>)}
                </div>
            </div>
    }

    return (
        <React.Fragment>
            <section className={`${zoneName} lift`}>
                {
                    groupedArray.map((group, i) => {
                        if(!!group.length && group[0].module == 'StartNewsletterWrapUp') {
                            return <WrapUpComponent group={group} key={i}/>
                        }
                        return <RenderComponent group={group} key={i} />
                    })
                }
            </section>
        </React.Fragment>
    )
}

export default ZoneRender;
