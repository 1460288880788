import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import RichText from './../RichText/RichText';
import Button from './../Button/Button';

const Slideshow = ({slideshowItems}) => {
    const [items, setItems] = useState(false);
    useEffect(() => {
        async function getSlideshowItem() {
            if (window.__agilityApi && !!slideshowItems && !!slideshowItems.referencename)   {
                let items = await window.__agilityApi.getContentList(slideshowItems.referencename);
                !!items && setItems(items.items);
            }
        }
        getSlideshowItem()
    }, [])

    const sliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 5000
    }
    return<div className='slideshow'>
        <Slider {...sliderSettings}>
            {!!items && items.map((item, i) => {
                return <div key={i} className={`slideshow-item`}>
                    <div style={{backgroundImage: !!item.fields.backgroundImage ? 'url(' + item.fields.backgroundImage.url + ')' : ''}} className={`bckg bg-g-${item.fields.backgroundGradient} with-bg-g-${item.fields.backgroundGradient}`}>
                        <div className="row">
                            <div className="content">
                                {!!item.fields.content && <RichText content={item.fields.content}/>}
                                {!!item.fields.cTA && <Button style={item.fields.backgroundGradient === 'primary' ? 'secondary' : false} href={item.fields.cTA.href} target={item.fields.cTA.target} text={item.fields.cTA.text}/>}
                            </div>
                        </div>
                    </div>
                </div>
            })}
        </Slider>
    </div>
}

export default Slideshow;
