import React from 'react';
import Button from './../Button/Button';
import Image from './../Image/Image';

const MediaAndText = ({textContent, cTA, image, video, mediaAlignment}) => {
    let videoId;
    if(!!video){
        videoId = JSON.parse(video).video_id
    }

    return <div className={`media-and-text align-${mediaAlignment}`}>
        <div className="row">
            <div className="media">
                {!!video ? <div className="video-container">
                <iframe src={`https://www.youtube.com/embed/${videoId}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div> : !!image ? <div className="image-container">
                    <Image src={image.url} alt={image.label}/>
                </div> : null}
            </div>
            <div className="text">
                {!!textContent && <div dangerouslySetInnerHTML={{__html: textContent}}></div>}
                {!!cTA && <Button href={cTA.href} target={cTA.target} text={cTA.text}/>}
            </div>
        </div>
    </div>
}

export default MediaAndText;
