import React, {useState, useEffect} from 'react';
import FormatDate from './../../helpers/formatDate';
import _ from 'lodash'

const NewsCard = ({newsitem, detailUrl}) => {
  return (<article className="newscard">
        { !!newsitem.thumbnail && <figure><a href={detailUrl.path}><img src={newsitem.thumbnail.url} alt={newsitem.thumbnail.label} /></a></figure> }
        {!!newsitem.date && <small className="date">{FormatDate(newsitem.date)}</small>}
        <a href={detailUrl.path}><h3>{newsitem.title}</h3></a>
        <div dangerouslySetInnerHTML={{__html: newsitem.excerpt}} />
        {/* <div dangerouslySetInnerHTML={{__html: 'News And Updates'}} /> */}
  </article>)
}

const NewsandEventsListing = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [sitemapItems, setSitemapItems] = useState();
  const newsPerPage = 9;

  useEffect(() => {
    async function getNewsItems() {
        if (window.__agilityApi)   {
            let items = await window.__agilityApi.getContentList("newsandevents", pageNumber, newsPerPage, 'en-ca');
            let _sitemapItems = await window.__agilityApi.getSitemapNested('en-ca');
            // let tmp = !!items && _.sortBy(items.items, m => m.fields.title)
            !!items && setNewsItems(!!items && items.items || []);
            !!_sitemapItems && setSitemapItems(_sitemapItems.filter(m => m.title === "Home").map(m => m.children)[0]);
        }
    }
    getNewsItems()
  }, []);

  const handleShowMore = async () => {
    let pnum = pageNumber + 1;
    const skip = newsPerPage * pnum;
    let items = await window.__agilityApi.getContentList("newsandevents", skip, newsPerPage, 'en-ca');
    setNewsItems([...newsItems, ...items.items]);
    setPageNumber(pnum)
  }
  
  return (<div className="row NewsandEventsListing">
    <div className="newscontainer">
      {newsItems.map( (item,idx) => { 
        let detailUrl = !!sitemapItems ? sitemapItems.filter(i => i.contentID === item.contentID)[0] : '';
          return <NewsCard newsitem={item.fields} key={idx} detailUrl={detailUrl}/>
          } )}
    </div>
    <div className="newscontainer">
      <button className="button style-primary" onClick={handleShowMore}>
        Show More
      </button>
    </div>
  </div>)
};

export default NewsandEventsListing;
