import React, {useEffect, useState} from 'react'
import _ from 'lodash'

const MobileBoardOfDirectors = ({ director, mobileView }) => {
    let emailAddress = '';
    if (director.fields.directorEmail && mobileView) {
        emailAddress = 'email';
    } else if (director.fields.directorEmail) {
        emailAddress = director.fields.directorEmail.text;
    }

    return (
        <div className="director">
            <div className="director-image">
                {!!director.fields.directorEmail ?  
                    <a href={director.fields.directorEmail ? director.fields.directorEmail.href : '#' } target= {director.fields.directorEmail ? director.fields.directorEmail.target : '_self'}>
                    <img src={!!director.fields.directorImage ? director.fields.directorImage.url : require('./no-profile.jpg')} alt={!!director.fields.directorImage ? director.fields.directorImage.label : ""} />
                    </a>
                : <img src={!!director.fields.directorImage ? director.fields.directorImage.url : require('./no-profile.jpg')} alt={!!director.fields.directorImage ? director.fields.directorImage.label : ""} /> }
            </div>
            <h2 className="director-name">{director.fields.directorName}</h2>
            <h4 className="director-position">{director.fields.directorPosition}</h4>
            <h4 className="director-email"><a href={director.fields.directorEmail ? director.fields.directorEmail.href : '#' } target= {director.fields.directorEmail ? director.fields.directorEmail.target : '_self'}>{emailAddress}</a></h4>
        </div>
    )
}

const DesktopBoardOfDirectors = ({directors}) => (<div className={`director-container`}>
        <div className="row">
            <div className="directors-row">
                {!!directors.length && directors.map((d, j) => (<MobileBoardOfDirectors director={d} key={j}/>))}
            </div>
        </div>
    </div>
)

const BoardOfDirectors = ({boardOfDirectors}) => {
    const [directors, setDirectors] = useState([]);
    const [mobileView, setMobileView] = useState(false);
    useEffect(() => {
        async function initial () {
            const data = await window.__agilityApi.getContentList(boardOfDirectors.referencename)
            if(window.innerWidth > 768) {
                const result = new Array(Math.ceil(data.items.length / 3))
                            .fill()
                            .map(_ => data.items.splice(0, 3))
                setDirectors(result)
                setMobileView(false)
                setTimeout(() => {
                    const $directorRow = document.querySelector('.board-of-directors > .director-lsiting > .director-container .directors-row');
                    const $row = document.querySelector('.board-of-directors > .director-lsiting > .director-container > .row');
                    const tmpDirectorStyle = $directorRow.currentStyle || window.getComputedStyle($directorRow);
                    const tmpRowStyle = $row.currentStyle || window.getComputedStyle($row);
                    const marginLeft = tmpDirectorStyle.marginLeft.split('px');
                    const paddingRight = tmpDirectorStyle.paddingRight.split('px');
                    const rowPaddingRight = tmpRowStyle.paddingRight.split('px');
                    const directorRight = $directorRow.clientWidth + ((window.innerWidth - $row.clientWidth + Number(rowPaddingRight[0])) / 2);
                    const directorLeft = Number(marginLeft[0]) + directorRight - Number(paddingRight[0]);
    
                    document.documentElement.style.setProperty('--directorsLeftWidth', `${directorLeft}px`)
                    document.documentElement.style.setProperty('--directorsRightWidth', `${directorRight}px`)
                }, 100)
            } else {
                !!data && setDirectors(data.items)
                setMobileView(true)
            }
        }
        window.addEventListener('resize', initial);
        initial()
    }, [])

    return <section className="board-of-directors">
        <div className={`director-lsiting ${mobileView && "listing-grid"}`}>
            {!!directors.length && directors.map((director, i) => (
                !!Array.isArray(director) ? 
                    <DesktopBoardOfDirectors directors={director} key={i}/> : 
                    <MobileBoardOfDirectors mobileView={mobileView} director={director} key={i}/>
            ))}
        </div>
    </section>
}

export default BoardOfDirectors;
