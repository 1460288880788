import React, {useState, useEffect} from 'react';
import axios from 'axios'
import menuProps from './menuProps';
import hamburger from './menu.svg';
import search from './search.svg';
import logo from './logo.svg';
import close from './close.svg';
import loading from './loading.svg';
import SiteWideAlert from './../SiteWideAlert/SiteWideAlert'

const Arrow = ({className='arrow'}) => (<svg className={className} width="10px" height="16px" viewBox="0 0 10 16">
<defs>
    <polygon id="path-1" points="9.33599944 1.49599991 7.91999953 0.0799999952 0 7.99999952 7.91999953 15.9199991 9.33599944 14.5039991 2.83199983 7.99999952"></polygon>
</defs>
<g id="Design" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
    <g id="(m)-nav-2" transform="translate(-368.000000, -132.000000)">
        <g id="Group-4">
            <g id="Group" transform="translate(35.000000, 111.000000)">
                <g id="ic/arrow_back_ios" transform="translate(338.000000, 29.000000) scale(-1, 1) translate(-338.000000, -29.000000) translate(333.000000, 21.000000)">
                    <g>
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1"></use>
                        </mask>
                        <use id="Path" fill="currentColor" fillRule="nonzero" xlinkHref="#path-1"></use>
                    </g>
                </g>
            </g>
        </g>
    </g>
</g>
</svg>);

const SearchIcon = ({className="searchicon", color="currentColor"}) => (<svg className={className} width="17px" height="18px" viewBox="0 0 17 18">
<g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="(m)-nav-2" transform="translate(-360.000000, -32.000000)" fill={color}>
        <g id="" transform="translate(357.000000, 29.000000)">
            <path d="M15.5,14 L14.71,14 L14.43,13.73 C15.63,12.33 16.25,10.42 15.91,8.39 C15.44,5.61 13.12,3.39 10.32,3.05 C6.09,2.53 2.53,6.09 3.05,10.32 C3.39,13.12 5.61,15.44 8.39,15.91 C10.42,16.25 12.33,15.63 13.73,14.43 L14,14.71 L14,15.5 L18.25,19.75 C18.66,20.16 19.33,20.16 19.74,19.75 C20.15,19.34 20.15,18.67 19.74,18.26 L15.5,14 Z M9.5,14 C7.01,14 5,11.99 5,9.5 C5,7.01 7.01,5 9.5,5 C11.99,5 14,7.01 14,9.5 C14,11.99 11.99,14 9.5,14 Z" id="🔹Icon-Color"></path>
        </g>
    </g>
</g>
</svg>)

const MobileHeader = ({primaryMenu=menuProps.primaryMenu, quickLinks=menuProps.quickLinks, primaryMenus, siteAlert}) => {
    const [open, setOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [currentMobileOverlay, setMobileOverlay] = useState(false);
    const [prevItem, setPrevItem] = useState(false);
    const [cse, setCSE] = useState(null);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [innerWidth, setInnerWidth] = useState('');

    useEffect(() => {
        async function initial () {
            setInnerWidth(window.innerWidth);
            if(!!!cse) {
                const setting = await window.__agilityApi.getContentItem(847);
                !!setting && setCSE({apiKey: setting.fields.apiKey, searchengineID: setting.fields.searchengineID})
            }

            if(window.innerWidth < 768) {
                const $header = document.querySelector('.header-mobile .navbar');
                const $body = document.querySelector('body');
                !!$header && setHeaderHeight($header.clientHeight)
                !!$body && $body.setAttribute('style', `padding-top: ${$header.clientHeight}px !important`)
            }
        }
        initial()
    }, [innerWidth]);

    const handleMenuOverlay = (e, children, prevItem=false) => {
        if (!!children && children.length > 0 && prevItem.name != 'annual-reports') {
            e.preventDefault();
            setMobileOverlay(children);
            setPrevItem(prevItem);
        }
    }

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        axios.get(`https://www.googleapis.com/customsearch/v1?key=${cse.apiKey}&cx=${cse.searchengineID}&q=${e.target.value}`)
        .then(res => {
            let r = [];
            console.log({res})
            !!res.data.items && res.data.items.map(item => {
                r.push({
                    title: item.title,
                    url: item.link,
                    content: item.snippet
                })
            })
            setResults(r)
        })
        .catch(error => {
            console.log({error})
            setResults([])
        })
    }

    const handleCloseAlert = () => {
        setTimeout(() => {
            const $header = document.querySelector('.header-mobile .navbar');
            const $body = document.querySelector('body');
            !!$header && setHeaderHeight($header.clientHeight)
            !!$body && $body.setAttribute('style', `padding-top: ${$header.clientHeight}px !important`)
        }, 100);
    }
    const mobileNavStyles = {top: `${headerHeight}px`, height: `calc(100vh - ${headerHeight}px)`};
    return (<div className={`header-container open-${open}`}>
        <nav className={`header-mobile open-${open}`}>
            <div className="navbar">
                {!!siteAlert && siteAlert.map((alert, i) => <SiteWideAlert text={alert.text} backgroundColor={alert.backgroundColor} onClose={handleCloseAlert} key={i}/>)}
                <div className="nav-header">
                    <div onClick={ e => {setOpen(!open); setSearchOpen(false)} } className={`toggleMenu isopen-${open}`}>
                        <img src={open ? close : hamburger} alt="Toggle Menu"/>
                    </div>
                    <a href="/" className="logo">
                        <img src={logo} alt="Toggle Menu"/>
                    </a>
                    <div onClick={ e => {setSearchOpen(!searchOpen); setOpen(false)} } className={`search toggleMenu isopen-${searchOpen}`}>
                        <img src={searchOpen ? close : search} alt="Toggle Menu"/>
                    </div>
                </div>
            </div>
            {open && <ul className="panel mobileChildren" style={mobileNavStyles}>
                <li>
                    <a className="mainMenuLink"><span><img src={hamburger} alt="Toggle Menu"/> Main Menu</span></a>
                </li>
                {primaryMenus.map( (item,idx) => (<li key={idx}>
                    <a href={item.path} className="mobile-nav-title">{item.title}</a><a onClick={ e => handleMenuOverlay(e, item.children) } className="arrow-alink">{!!item.children && <Arrow />}</a>
                </li>))}
                {!!currentMobileOverlay && <div className="mobileoverlay panel" style={mobileNavStyles}>
                    <ul>
                        <li onClick={ e => setMobileOverlay(false) } className="back-arrow"><Arrow className="arrow inverted" /> Back</li>
                        {currentMobileOverlay.map( (item,idx) => (<li key={idx}>
                            <a href={item.path} className="mobile-nav-title">{item.title}</a><a onClick={ e => handleMenuOverlay(e, item.children, item) } className="arrow-alink"> {!!item.children && item.name != 'annual-reports' && <Arrow />}</a>
                        </li>))}
                    </ul>
                </div>}
            </ul>}
            {searchOpen && <div className="panel searchPanel">
                <div className="searchform">
                    <div className="search-controls">
                        <input type="text" placeholder="Search Keyword" value={searchTerm} onChange={ handleSearch } />
                        <SearchIcon />
                    </div>
                    <div className="search-results">
                        {!!results.length && <ul>
                            {results.map((r, i) => <li key={i}>
                                <a href={r.url}>{r.title}</a>
                            </li>)}
                        </ul>}
                        {!!!results.length && !!searchTerm && <img src={loading} alt="loading..." className="search-loading"/>}
                    </div>
                </div>
            </div>}
        </nav>
    </div>);
}

const DesktopHeader = ({primaryMenu=menuProps.primaryMenu, quickLinks=menuProps.quickLinks, primaryMenus, siteAlert}) => {
    const [currentOverlayItem, setCurrentOverlayItem] = useState(false);
    const [currentMenuIndex, setCurrentMenuIndex] = useState(false);
    const [currentSubnavItem, setSubnavItem] = useState(false);
    const [currentTopItem, setCurrentTopItem] = useState(false);
    const [globalNavigationBar, setGlobalNavigationBar] = useState(null)
    const [activeSearch, setActiveSeach] = useState(false)
    const [overlayItemActive, setOverlayItemActive] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');
    const [alertHeight, setAlertHeight] = useState(0);
    const [innerWidth, setInnerWidth] = useState('');

    useEffect(() => {
        async function GetGlobalHeader () {
            setInnerWidth(window.innerWidth);
            const globalNav = !!!globalNavigationBar ? await window.__agilityApi.getContentItem(98) : null;
            if(!!globalNav && !!globalNav.fields.globalNavigationBar) {
                setGlobalNavigationBar(globalNav.fields.globalNavigationBar)
            }
            if(window.innerWidth > 768) {
                const $desktopHeader = document.querySelector('.desktopheader');
                const $body = document.querySelector('body');
                !!$desktopHeader && setAlertHeight($desktopHeader.clientHeight)
                !!$body && $body.setAttribute('style', `padding-top: ${$desktopHeader.clientHeight}px !important`)
            }
        }
        GetGlobalHeader()
    }, [innerWidth])
    
    const overLayhasGrandChild = !!currentOverlayItem ? currentOverlayItem.filter( child => !!child.children ).length > 0 : false;

    const handleOpenSearch = () => {
        setActiveSeach(!activeSearch);
    }

    const handleMouseEnter = () => {
        setCurrentOverlayItem(primaryMenus);
        setCurrentMenuIndex(100000)
        setTimeout(() => {
            setOverlayItemActive(true);
        }, 10)
    }
    const handleNavigationMouseLeave = () => {
        setTimeout(() => {
            setCurrentOverlayItem(false);
            setCurrentMenuIndex(false);
        }, 350)
        setTimeout(() => {
            setOverlayItemActive(false);
        }, 200)
    }

    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
    }

    const handleCloseAlert = (e) => {
        setTimeout(() => {
            const $desktopHeader = document.querySelector('.desktopheader');
            const $body = document.querySelector('body');
            !!$desktopHeader && setAlertHeight($desktopHeader.clientHeight)
            !!$body && $body.setAttribute('style', `padding-top: ${$desktopHeader.clientHeight}px !important`)
            console.log('handleCloseAlert')
        }, 100)
    }

    return  ( 
    <React.Fragment>
        <div className="desktopheader">
            {!!siteAlert && siteAlert.map((alert, i) => <SiteWideAlert text={alert.text} backgroundColor={alert.backgroundColor} onClose={handleCloseAlert} key={i}/>)}
            {!!globalNavigationBar && <div className="global-navigation" dangerouslySetInnerHTML={{__html: globalNavigationBar}}></div>}
            <div className="row">
                <div className="desktop-header-container">
                    <nav className="mainnav">
                        <div className="navpart">
                            <div
                                onMouseLeave={ e => {
                                    setCurrentOverlayItem(false);
                                    setCurrentMenuIndex(false)
                                }}
                                onMouseEnter={ e => {
                                    setCurrentOverlayItem(false);
                                    setCurrentMenuIndex(false)
                                }} className="logo">
                                <a href="/"><img src={logo} alt="Toggle Menu"/></a>
                            </div>
                            <div onMouseEnter={handleMouseEnter}
                                className={`primaryMenuToggle isopen-${100000 === currentMenuIndex}`}>
                                <img src={hamburger} alt="Toggle Menu"/>
                            </div>
                        </div>
                        <ul className="navpart">
                        {primaryMenus.map( (item,idx) => (<li className={`isopen-${idx === currentMenuIndex}`} key={idx}>
                            <a onMouseEnter={ e => {
                                setCurrentOverlayItem(item.children);
                                setCurrentMenuIndex(idx)
                                setCurrentTopItem(item);
                                setTimeout(() => {
                                    setOverlayItemActive(true);
                                }, 300)
                                } } 
                                href={item.path}>{item.title}</a>
                        </li>))}
                        </ul>
                        <div className="searchbox">
                            {!!activeSearch && <form method="GET" action="/search-results">
                                <input type="text" name="s" className="control-search" placeholder="Search Keyword" onChange={handleSearch} />
                            </form>}
                            <button className={activeSearch ? "active" : ''} onClick={handleOpenSearch}>
                                <SearchIcon color={!!activeSearch ? "#42425d" : "#FFF"} />
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
        </div> 
    {currentOverlayItem && 
        (<div onMouseLeave={handleNavigationMouseLeave} className={`headeroverlay ${!!overlayItemActive && 'active'}`} style={{top: `${alertHeight}px`}}>
            <div className="row">
                <div className={`overlayMenu hasgrandchild-${overLayhasGrandChild}`}>
                    <div className="menuholder">
                        {overLayhasGrandChild ? 
                        (<div className="withGrandchild">
                            <ul>
                            { currentOverlayItem.map( (item, idx) => <li className="hasgrandchild" key={idx}>
                                <a className="overlaymenulink" href={item.path}>{item.title}</a>
                                {!!item.children && <ul className="grandchild">
                        {item.children.map( (_item, ind) => <li key={ind}><a href={_item.path}>{_item.title}</a></li> )}
                                </ul>}
                            </li>) }
                            </ul>
                        </div>) : 
                        (<div className={`nograndchild sixormore-${currentOverlayItem.length > 6}`}>
                            <ul>
                            {currentOverlayItem.map( (item, idx) => <li key={idx}><a className="overlaymenulink nograndchild" href={item.path}>{item.title}</a></li>)}
                            </ul>
                        </div>)}
                    </div>
                    <div className="descholder">
                        
                    </div>
                </div>
            </div>
        </div>)}
    </React.Fragment>)
}


const Header = (props=menuProps) => {
    const [primaryMenus, setPrimaryMenus] = useState([]);
    useEffect(() => {
        async function getPrimaryMenus() {
            let menus = await window.__agilityApi.getSitemapNested();
            if(!!menus) {
                menus = menus.filter(menu => menu.name !== "home" && menu.name !== "components" && menu.name !== "search-results")
                setPrimaryMenus(menus)
            }
        }
        getPrimaryMenus();
    }, [])
    return (<React.Fragment>
        <MobileHeader {...props} {...{primaryMenus}}/>
        <DesktopHeader {...props} {...{primaryMenus}}/>
    </React.Fragment>)
}

export default Header;
