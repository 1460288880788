import React from 'react';
import RichText from './../RichText/RichText';
import Button from './../Button/Button';
import Image from './../Image/Image';

const Hero = ({image, backgroundImage, backgroundOverlay, cTA, content}) => {
    return<div className={`hero ${!!image ? 'with-image' : ''}`}>
        <div className={`background-container bckg bg-g-${backgroundOverlay}`} style={{backgroundImage: !!backgroundImage ? 'url(' + backgroundImage.url + ')' : ''}}></div>
        <div className="row">
            <div className={`content-container with-bg-g-${backgroundOverlay}`}>
                {!!content && <RichText content={content}/>}
                {!!cTA && <Button style={backgroundOverlay === 'primary' ? 'secondary' : false} href={cTA.href} target={cTA.target} text={cTA.text}/>}
            </div>
            <div className="image-container">
                {!!image && <Image src={image.url} alt={image.label}/>}
            </div>
        </div>
    </div>
}

export default Hero;
